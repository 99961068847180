/* Add this to your CSS file or style block */

.app-header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-top: 0%;
}

.logo {
  width: auto;
  max-height: 40px;
  margin-left: 0;
  margin-right: -10px;
}

.search-bar {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:flex;
  border-radius: 20px;
  padding: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 13px;
  border: 1px solid #d6d6d6;
  background-color: white;
}

.search-bar.white{
  border:  white;
}

.search-input-box {
  width: 100%;
}

.activity-search,
.location-search {
  position: relative;
  margin-right: 10px;
  display: flex;
  width: 100%;
}
.geolocation-search-bar {
  position: absolute;
  top: 100%; /* Position it below the search bar */
  right: 0;
  z-index: 2; /* Ensure it appears on top of other elements */
  background-color: white;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 100px; /* Adjust the width as needed */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}



.search-box {
  display: flex;
  align-items: center;
}

.search-icon {
  margin-right: 10px;
  align-self: center;
  /* Adjust the margin as needed */
}

.search-icon svg {
  width: 22px;
  /* Set the width of the SVG to match its original size */
  height: 22px;
  /* Set the height of the SVG to match its original size */
}



input {

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  outline: none;
  background: none;
  width: 200px;
  /* Adjust as needed */
  padding: 5px;
}

.header-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 15px;
}


.venue-name{
  text-decoration: none;
  color: black !important;
}

.header-button {
  text-decoration: none;
  color: black;
  padding: 2px 2px;
  border-radius: 5px;
  font-size:15px;
}

.toggle-button {
  background-color: #d61616c8;
  color: white;
  border: none;
  padding: 12px 15px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom:10px;
}

/* Vertical Filter Buttons */
.filter-button {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 15px;
  /* Adjust as needed */
  color: black;
  padding: 8px 16px;
  /* Adjust as needed */
  margin-top: 10px;
  margin-bottom: 8px;
  /* Adjust as needed */
  margin-left: 10px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.filter-button:hover {
  background-color: #f0f0f0;
  /* Adjust as needed */
  box-shadow: #5f6368;
  border: 1px solid red;
}

.filter-controls {
  border-top: 1px solid lightgray;
}

.filter-clear-button {
  padding: 5px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 5px;
  color: black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.filter-done-button {
  padding: 5px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 5px;
  color: black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Activities Filter */
.activities-filter {
  display:block;
  position: absolute;
  top: 115px; /* Position below the Activities button */
  left: 15px;
  z-index: 2; /* Ensure it appears on top of the grid */
  background-color: white;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  margin-top: 8px;
  margin-right: 15px;
  width: fit-content;
  box-shadow: #5f6368;
 
}

.activity-options {
  display: flex;
  flex-wrap: wrap;
}

.activity-options label {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
  font-size: 8;
}

.activity-options input[type="checkbox"] {
  width: 15px;
  height: 15px;
  border: 1px solid red;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
}

.activity-options input[type="checkbox"]:checked {
  background-color: red;
  border-color: red;
  color: white;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.dashboard-container {
  display: flex;
  height: 84vh;
  margin-top: 1vh;
}

.grid {
  width: 50%;
  height: 100%;
  margin-left: 5px;
  margin-right: 5px;
  /* Adjust as needed */ 
}

.map {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

/* Add this to your CSS file or component */
.data-grid-row:hover,
.data-grid-row:focus {
  outline: none;
}

/* Add this to your existing stylesheet or in a style tag in your component */
.disable-hover-effect {
  cursor: default !important;
  pointer-events: none !important;
  user-select: none !important;
  background-color: transparent !important;
}

/* Responsive styles */

/* Both map and grid are displayed by default */
.map, .grid {
  display: block;
}

/* This will only apply when the screen width is less than 768px */
@media screen and (max-width: 768px) {
  /* Hide both by default */
  .map, .grid {
    display: none;
  }

  /* Use a class to control the visibility toggled by the state */
  .map.visible, .grid.visible {
    display: block;
    width: 100%
  }

  .map.map.visible{
    height: 100vh
  }

  .class-details{
    display: none !important;
  }

  .venue-info{
    width: 100%;
  }

  .dashboard-container{
    height: auto;
    margin-top: 0;
  }
} 

  /* Hide the toggle button on larger screens */
@media screen and (min-width: 768px) {
  .toggle-button-container {
    display: none;
  }
}


.app-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 
    "logo search buttons";
  gap: 10px;
  align-items: center;
  padding: 0 20px; 
}

.website-container {
  padding: 10px;
}

@media (max-width: 768px) {
  .app-header {
    /* Define a new grid template for smaller screens */
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "logo"
      "buttons"
      "search";
    gap:0;
    padding:0;
  }

  .logo {
    grid-column: 1;
    grid-row: 1;
    max-height: 30px;
  }

  .header-buttons {
    grid-column: 2/4;
    grid-row:1;
  }

  .search-bar{
    grid-column: 1/3;
    grid-row:2;
  }

  .toggle-button-container{
    grid-column: 3;
    grid-row:2;
  }

  .logo {
    margin-left:8px;
  }

  .header-buttons{
    display:none;
  }
}